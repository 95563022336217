import * as Api from '@/api/course/course.js'
import ClassSchedule from '@/components/ClassSchedule/ClassSchedule.vue'
import RelationCourse from '@/components/RelationCourse/RelationCourse.vue'
import CourseMaterial from '../../views/User/LiveDetail/LiveClassDetail/Materials.vue'
import Comment from '@/components/Comment/Comment.vue'
import TCPlayer from '@/components/TCPlayer/TCPlayer.vue'
import store from '@/store'
import { userSign } from '@/api/live/live'
import { ANALYTIC_TYPE } from '@/enums/API'
import { mapState } from 'vuex'
import { courseWatch } from '@/api/course/course.js'
import {
  classesCourseWatchDuration,
  courseAnalystic,
} from '@/api/common/common'

export default {
  components: {
    ClassSchedule,
    RelationCourse,
    Comment,
    CourseMaterial,
    TCPlayer,
  },
  data () {
    return {
      activeCourse: {},
      activeTeacher: {},
      activeName: 'detail',
      courseDetailData: [],
      classSchedule: [],
      teacherLevel: [],
      leastQty: 1,
      unitPrice: 1,
      relationCourse: [],
      maxBuyNum: 999,
      buyNum: 0,
      comment: [],
      commentPagination: {},
      videoUrl: '',
      currentCourseId: '',
      CourseMaterialData: [],
      currentCourse: {},
      isBuy: 1, // type= 1 未购买  type=2  购买后,
      // courseDesc: {},
      playerOptions: {
        height: '360',
      },
      isShowQrcode: false,
      timeCount: 1,
      timeInterval: null,
      playFlag: true, // 是否统计点击播放
      intervalObj: null,
      videoDruation: 0,
      currentVideoFid: '',
    }
  },
  props: ['planId', 'courseId'],
  mounted () {
    if (this.$route.path.indexOf('user') > 0) {
      this.isBuy = 2
    }
    // if (this.isBuy === 2) {
    //     this.getCourseDesc()
    // }
    this.getCourseDetail()
    if (this.isBuy === 2) {
      this.activeName = 'schedule'
      this.handleClick({ name: 'schedule' })
    }
    this.getUserLiveToken()
  },
  activated () {
    this.getCourseData()
  },
  computed: {
    showPrice: function () {
      return this.buyNum * this.unitPrice
    },
    courseDetailType: function () {
      if (this.$route.path.indexOf('user') > 0) {
        return 2
      } else {
        return 1
      }
    },
    postPic () {
      if (this.courseDesc && this.courseDesc.course) {
        return this.courseDesc.course.cover_image_url
      } else {
        return ''
      }
    },
    ...mapState({
      courseDesc: ( state ) => state.course.currentCourseDesc,
    }),
  },
  methods: {
    getCourseDetail () {
      if (this.courseDetailType == 1) {
        Api.courseDetail(
            '',
            ( res ) => {
              this.courseDetailData = res
              this.teacherLevel = res.attributes[ 0 ]
              this.leastQty = res.attributes[ 0 ].teacher_level[ 0 ].least_sale_qty
              this.buyNum = res.attributes[ 0 ].teacher_level[ 0 ].least_sale_qty
              this.unitPrice = res.attributes[ 0 ].teacher_level[ 0 ].unit_price
              this.activeCourse = this.teacherLevel
              this.activeTeacher = this.teacherLevel.teacher_level[ 0 ]
            },
            '',
            this.courseId,
            'v10'
        )
      } else {
        Api.classCourse(
            '',
            ( res ) => {
              this.courseDetailData = res
              if (localStorage[ this.courseId ]) {
                let localCourse = JSON.parse(localStorage[ this.courseId ])
                this.videoUrl = localCourse.video_url
                this.currentCourse = localCourse
                this.currentCourseId = localCourse.id
                this.currentVideoFid = localCourse.video_id
                // this.changeVideo(localCourse);
              } else {
                this.videoUrl = res[ 0 ].children[ 0 ].video_url
                this.currentCourse = res[ 0 ].children[ 0 ]
                this.currentCourseId = res[ 0 ].children[ 0 ].id
                // this.changeVideo(res[0].children[0]);
                this.currentVideoFid = res[ 0 ].children[ 0 ].video_id
              }
              sessionStorage[ 'videoWatchRecords' + this.courseId ] =
                  JSON.stringify({})
              this.$nextTick(() => {
                this.$refs.childplayer.initPlay(this.currentVideoFid)
              })
            },
            this.planId
        )
      }
    },
    getCourseDesc () {
      Api.courseDetail(
          '',
          ( res ) => {
            // this.courseDesc = res
            this.setCurrentCourse(res)
          },
          '',
          this.courseId
      )
    },
    handleChange ( item ) {
      this.buyNum = item
    },

    // tab 切换
    handleClick ( item ) {
      switch (item.name) {
        case 'schedule':
          Api.classCourse(
              '',
              ( res ) => {
                this.classSchedule = res
              },
              this.planId
          )
          break
        case 'relation':
          Api.relationCourseApi(this.courseId, ( res ) => {
            this.relationCourse = res
          })
          break
        case 'comment':
          break
        case 'materials':
          Api.courseMaterials(this.planId, ( res ) => {
            this.CourseMaterialData = res
          })
      }
    },
    computedTeacherLevel ( item ) {
      this.teacherLevel = item
      this.activeCourse = item
      this.leastQty = item.teacher_level[ 0 ].least_sale_qty
      this.buyNum = item.teacher_level[ 0 ].least_sale_qty
      this.unitPrice = item.teacher_level[ 0 ].unit_price
      this.activeTeacher = this.teacherLevel.teacher_level[ 0 ]
    },
    computedLeastQty ( item ) {
      this.activeTeacher = item
      this.leastQty = item.least_sale_qty
      this.buyNum = item.least_sale_qty
      this.unitPrice = item.unit_price
    },
    subComment ( data ) {
      let params = {
        course_id: this.courseId,
        star: data.star,
        content: data.content,
      }
      Api.subCourseCommentApi(params, () => {
      })
    },
    changeVideo ( item ) {
      let oldCourseId = this.currentCourseId
      const lastSecond = Math.floor(this.$refs.childplayer.getCurrentTime())
      localStorage[ this.courseId ] = JSON.stringify(item)
      let watchRecordsObj =
          (sessionStorage[ 'videoWatchRecords' + this.courseId ] &&
              JSON.parse(sessionStorage[ 'videoWatchRecords' + this.courseId ])) ||
          {}
      // watchRecordsObj[this.currentCourse.id] = lastSecond;
      let level = this.currentCourse.video_duration * 0.97
      if (watchRecordsObj[ this.currentCourse.id ]) {
        watchRecordsObj[ this.currentCourse.id ] =
            watchRecordsObj[ this.currentCourse.id ].time >= level
                ? { time: lastSecond, is_finished: true }
                : { time: lastSecond, is_finished: false }
      } else {
        watchRecordsObj[ this.currentCourse.id ] = {
          time: lastSecond,
          is_finished: false,
        }
      }
      let oldWatchVideoRecord = watchRecordsObj[ this.currentCourse.id ]

      sessionStorage[ 'videoWatchRecords' + this.courseId ] =
          JSON.stringify(watchRecordsObj)
      if (this.intervalObj) {
        clearInterval(this.intervalObj)
        let params = {
          duration: this.videoDruation,
          last_second: lastSecond,
        }
        classesCourseWatchDuration(this.currentCourseId, params, () => {
          this.videoDruation = 0
          this.intervalObj = null
        })
      }
      this.playFlag = true
      this.currentVideoFid = item.video_id
      this.videoUrl = item.video_url
      this.currentCourseId = item.id
      this.currentCourse = item

      this.courseDetailData.forEach(( ll, index ) => {
        ll.children.forEach(( course, courseIndex ) => {
          if (course.id === oldCourseId) {
            console.log(
                course.id,
                this.courseDetailData[ index ].children[ courseIndex ]
            )
            let obj = { ...course }
            obj.last_second = oldWatchVideoRecord.time
            obj.is_finished = obj.is_finished
                ? true
                : oldWatchVideoRecord.is_finished

            this.$set(this.courseDetailData[ index ].children, courseIndex, obj)
          }
        })
      })
    },
    buyCourse () {
      if (this.courseDetailData.guide_price === 0) {
        this.$router.push({
          path: this.$route.path,
          query: {
            type: 2, // 1 立即购买 2 加入购物车
            id: this.$route.params.id,
          },
        })
      } else {
        let selectProduct = [
          {
            buy_qty: this.buyNum,
            course: this.courseDetailData,
            course_attribute: this.activeTeacher,
            course_subject: this.activeCourse,
            teacher_level: this.activeTeacher,
            id: this.courseDetailData.id,
          },
        ]
        sessionStorage.selectCourse = JSON.stringify(selectProduct)
        let products = selectProduct
        store.dispatch('updateSelectProduct', { products })
        this.$router.push({
          path: '/confirmCart',
          query: {
            type: 1, // 1 立即购买
          },
        })
      }
    },
    addToCart () {
      let product = {
        courseDetail: this.courseDetailData,
        chooseDetail: {
          teacherLevel: this.activeTeacher,
          subject: this.activeCourse,
          buyNum: this.buyNum,
        },
      }
      let params = {
        buy_qty: this.buyNum,
        course_id: this.courseId,
        course_subject_id: this.activeCourse.id,
        teacher_level_id: this.activeTeacher.id,
      }
      Api.shoppingCart('post', params, () => {
        store.dispatch('addProduct', { product })
      })
    },
    serviceBefore () {
      store.dispatch('setGlobalMask', { status: true })
      this.isShowQrcode = true
      let params = {
        type: ANALYTIC_TYPE.CLICK,
      }
      Api.courseAnalystic(this.courseId, params)
    },
    handleQrcode () {
      this.isShowQrcode = false
      store.dispatch('setGlobalMask', { status: false })
    },
    onPlayerPlay () {
      // let params = {
      //   course_id: parseInt(this.courseDesc.course.id),
      //   course_schedule_id: this.currentCourseId,
      // };
      if (this.playFlag) {
        courseWatch(this.currentCourseId, () => {
          console.log('统计成功')
          this.playFlag = false
        })
      }
      this.intervalObj = setInterval(() => {
        this.videoDruation += 1
        console.log(this.videoDruation)
      }, 1000)
    },
    onPlayerPause () {
      const lastSecond = Math.floor(this.$refs.childplayer.getCurrentTime())
      let params = {
        duration: this.videoDruation,
        last_second: lastSecond,
      }
      classesCourseWatchDuration(this.currentCourseId, params, () => {
        clearInterval(this.intervalObj)
        this.videoDruation = 0
        this.intervalObj = null
      })
    },
    // get im token
    getUserLiveToken () {
      userSign(( res ) => {
        sessionStorage.userSign = res.user_sign
      })
    },
  },
  beforeRouteEnter ( to, from, next ) {
    next(( vm ) => {
      vm.timeCount = 0
      vm.timeInterval = setInterval(() => {
        vm.timeCount++
      }, 1000)
    })
  },
  beforeRouteLeave ( to, from, next ) {
    clearInterval(this.timeInterval)
    let params = {
      duration: this.timeCount,
      type: ANALYTIC_TYPE.EXIT,
    }

    //  小于零表示是从首页点击进入的  需要统计  大于零表示从我的课程进入不需要统计
    if (this.$route.path.indexOf('user') < 0) {
      Api.courseAnalystic(this.courseId, params, () => {
      })
    } else {
      if (this.intervalObj) {
        clearInterval(this.intervalObj)
        const lastSecond = Math.floor(this.$refs.childplayer.getCurrentTime())
        let params = {
          duration: this.videoDruation,
          last_second: lastSecond,
        }
        classesCourseWatchDuration(this.currentCourseId, params, () => {
          this.videoDruation = 0
          this.intervalObj = null
        })
      }
    }
    if (this.videoUrl !== '') {
      this.$refs.childplayer.distory()
    }
    next()
  },

  watch: {
    $route ( newVal, oldVal ) {
      if (newVal !== oldVal) {
        if (this.$route.path.indexOf('user') > 0) {
          this.isBuy = 2
        }
        this.getCourseDetail()
      }
    },
  },
}
